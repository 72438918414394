import { find, get, map, reduce } from 'lodash-es';

import { TYPE_CHAPTER } from './sections';

export const mapImages = (elements, qrcode) => {
  const reducer = (obj, { id = '', defaultValue = null }) => {
    switch (id) {
      case 'authorImageFront':
        return { ...obj, userFrontImageUrl: defaultValue };
      case 'authorImageBack':
        return { ...obj, userBackImageUrl: defaultValue };

      case 'housingLogo':
        return { ...obj, fairHousingLogo: defaultValue };

      case 'brokerLogo':
        return { ...obj, brokerLogo: defaultValue };

      case 'realtorLogo':
        return { ...obj, realtorLogo: defaultValue };

      case 'background':
        return { ...obj, background: defaultValue };

      case 'qrCode':
        return { ...obj, qrCode: qrcode ? qrcode : defaultValue };

      default:
        return obj;
    }
  };

  return reduce(elements, reducer, {});
};

// eslint-disable-next-line consistent-return
export const getChapterNumber = (sections, sectionId) => {
  let chapterNumber = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const chapter of sections) {
    if (chapter.type === TYPE_CHAPTER) {
      // eslint-disable-next-line no-plusplus
      chapterNumber++;
      if (sectionId === chapter._id) {
        return chapterNumber;
      }
    }
  }
};

// TODO: Move to selectors
export const getElementsBySectionId = (sections, sectionId) => {
  const section = find(sections, ({ _id }) => _id === sectionId);

  const templates = get(section, 'templates', []);
  const template = find(templates, ({ isActive }) => isActive);
  const templateId = get(template, '_id');

  const elements = get(template, ['bookpage', 0, 'elements'], []);

  const chapterNumber = getChapterNumber(sections, sectionId);

  const replaceChapter = (element) => {
    const { id, defaultValue } = element;
    if (id !== 'title' || typeof defaultValue !== 'string' || !chapterNumber) {
      return element;
    }

    return {
      ...element,
      rawValue: undefined,
      defaultValue: defaultValue.replace(/#NUMBER#/g, chapterNumber.toString()),
    };
  };

  return {
    templateId,
    section,
    template,
    elements: map(elements, replaceChapter),
  };
};

export const getImageTypeByElementId = (elementId) => {
  switch (elementId) {
    case 'authorImageFront':
    case 'authorImageBack':
      return 'book_profile';
    case 'background':
      return 'background';
    default:
      return 'logo';
  }
};
