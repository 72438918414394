import join from 'url-join';

import env from 'environments';
import { get, includes, map } from 'lodash-es';
import {
  CONTACT_DETAILS_MODAL,
  FILE_PICKER_MODAL,
  PLAIN_TEXT_ELEMENTS,
  PLAIN_TEXT_MODAL,
  RICH_TEXT_MODAL,
} from './constants';

const isPlainTextModal = (elementId) => includes(PLAIN_TEXT_ELEMENTS, elementId);

export const getModalType = (elementId, elementType) => {
  if (elementId === 'brokerContact') {
    return CONTACT_DETAILS_MODAL;
  }

  if (elementType === 'image') {
    return FILE_PICKER_MODAL;
  }

  if (elementType === 'text') {
    return isPlainTextModal(elementId) ? PLAIN_TEXT_MODAL : RICH_TEXT_MODAL;
  }

  return null;
};

// TODO: Move to store
export const setLogoUrls = (logos, imageType) => {
  if (imageType !== 'logo') {
    return undefined;
  }

  return map(logos, (logo) => {
    const logoUrl = get(logo, ['logourl', 'url']);

    const isProduction = env.ENVIRONMENT === 'production';
    const resolvedSufix = isProduction ? env.GRAPHQL_URL : '';

    const url = join(resolvedSufix, logoUrl);

    return {
      ...logo,
      url,
    };
  });
};
