import { useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import QRCode from 'react-qr-code';
import { DownloadIcon } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';

const DownloadQRCode = ({ url, size}) => {
  const qrCodeRef = useRef(null);
  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function(dataUrl) {
        const parsedUrl = new URL(url);
        const subdomain = parsedUrl.hostname.split('.')[0];
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${subdomain}-qr.png`;
        link.click();
      })
      .catch(function(error) {
        console.error('Error generating QR code:', error);
      });
  };
  return (
    <div className="flex items-center justify-between gap-2">
      <div
        style={{ height: 'auto', margin: '0 auto', maxWidth: `${size}px`, width: '100%' }}
        className="border rounded-lg p-2"
      >
        <QRCode
          size={size}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={url}
          viewBox={`0 0 ${size} ${size}`}
          ref={qrCodeRef}
        />
      </div>
      <Button color="outlined" size="base" onClick={downloadQRCode}>
        <DownloadIcon />
        <span className='ml-2'>Download</span>
      </Button>
    </div>
  );
}

export default DownloadQRCode;