import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './homePricingGuide.png';

const isProduction = env.ENVIRONMENT === 'production';

const homePricingGuideDefinitions = {
  offerDescription: [
    'Your own editable Pricing Guide that you can print out and give to clients or send to them as a PDF. You can customize everything inside, edit the text, and add your logo or brand colors.',
    'Complete A-to-Z Instructions on how to set up the pricing guide to fit your personality and business.',
    'Easily get price reductions with the Pricing Guide.',
    'Build trust with sellers and start pricing conversations on the right foot.',
    'Demonstrate that you’re a knowledgeable and helpful professional.',
    'Bonus: The Complete Guide to Finding Unlimited Leads.',
  ],
  productHandler: 'home-pricing-guide',
  componentId: 'handle:clickfunnel-7',
  summaryTitle: 'Home Pricing Guide',
  summaryPrice: '$7',
  offerImage,
  upsell: {
    productHandler: 'home-pricing-guide',
    productPricePointHandle: 'home-pricing-guide',
    componentId: isProduction ? '2469883' : '2469774',
    component: () => <Upsell productName="Home Pricing Guide" />,
    offerId: isProduction ? '6776e2d8408fc29b9f3ee9ae' : '6773f3b773a2683c7cfd0e6b',
  },
  USOnly: false,
};

export default homePricingGuideDefinitions;
