const offerCustomPaths = {
  'seller-guide-tw-offer': 'sellerGuide',
  'buyer-guide-tw-offer': 'buyerGuide',
  'lep-tw-offer': 'loanEducationPacketOffer',
  'no-cold-calling-tw': 'nccFunnel',
  'no-cold-calling-pw': 'nccFunnel', // there is a mistake in staging environment
  'instant-listing-presentation-offer': 'listingPresentation',
  'instant-trust-package': 'trustOffer',
  'home-pricing-guide': 'homePricingGuide',
};

export default offerCustomPaths;
