import styled from 'styled-components';
import { Black, neutral300 } from 'modules/common/theme/color';
import { Tabs } from 'antd';

const Wrapper = styled.div`
  display: flex;
`;

const Tab = styled(Tabs)`
  .ant-tabs-nav-scroll {
    border-radius: 0px 0px 0px 0px !important;
  }

  .ant-tabs-nav-wrap {
    background: none;
    padding: 0 20px;

    .ant-tabs-nav-list {
      display: flex;
      column-gap: 20px;

      .ant-tabs-tab {
        padding: 8px 8px 15px 8px;
        color: ${Black};
        border-radius: 0 !important;
        margin-right: 0 !important;
        background: inherit !important;
        border: 0px !important;
      }

      .ant-tabs-tab-active {
        color: ${Black} !important;
        border-bottom: 3px solid #4cc9e1 !important;
      }
    }
  }
`;

const { TabPane } = Tabs;

const TabPaneWrapper = styled.div`
  width: 570px;
  height: auto;
  min-height: 460px;
  max-height: 60vh;
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  items-center: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  border: 0;

  img {
    padding: 0;
  }
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: ${neutral300};
  border-radius: 5px;
  padding: 5px;
`;

const GalleryContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(15px, 130px);
  grid-row-gap: 30px;
  grid-column-gap: 0;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  padding: 10px;
`;

const NoData = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  GalleryContainer,
  ImageWrapper,
  NoData,
  Wrapper,
  Tab,
  TabPane,
  TabPaneWrapper,
  UploadedImage,
};
